@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  .sidebar-icon {
    @apply relative flex items-center justify-center
            h-12 w-12 mt-1 mb-1 mx-auto
            bg-neutral-800 text-green-500
            hover:bg-green-600 hover:text-white
            rounded-3xl hover:rounded-xl
            transition-all duration-200 ease-linear
            cursor-pointer;
  }

  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-14
            rounded-md shadow-md
            text-white bg-neutral-900
            text-xs font-bold
            transition-all duration-100 scale-0 origin-left;  
  }
}


#Channel-Bar{
  width: 240px;
  overflow: scroll;
  height: 0vh;
}

#Channel-Bar::-webkit-scrollbar{
  display: none;
}

    #Channel-Bar-Header{
      height: 50px;
      border-bottom: 1px;
      border-style: solid; 
      border-color: rgb(31,32,35);
    }

    #Server-Name{
      color: rgb(231,232,235);
    }

    #User-Controls{
      min-height: 50px;
      background-color: rgb(35,36,40);
      display: grid;
      grid-template-columns: 56fr 44fr;
    }

      #Left-User-Control{
        width: 122px;
        height: 38px;
        overflow: hidden;
        justify-self: center;
        align-self: center;  
        cursor: pointer; 
      }

          #User-Cont-Info{
            width: 80px;
            height: 35px;
            overflow: hidden;  
            justify-content: center;  
            flex-shrink: 1;     
          }

          #User-Name{
            color: rgb(242,242,242);
            font-size: 14px;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          #User-ID{
            font-size: 12px;
          }

          #User-Icon-Div{
            display: flex;  
            flex-shrink: 1;         
          }

              #User-Icon{
                height: 32px;
                width: 32px;
                border-radius: 50%;
              }

      #Right-User-Control{
        width: 110px;
      }

        #Control-Icon{
          padding: 9px;
          border-radius: 0.375rem;
          cursor: pointer;
        }

#Sidebar{
  min-width: 70px;
  max-width: 4.5rem;
}

#People{
  width: 240px;
}

#Main-Body{
  width: 100%;
  min-width: 350px;
}

    #Main-Header{
      height: 50px;
      border-bottom: 1px;
      border-style: solid; 
      border-color: rgb(31,32,35);
      font-weight: 500;
    }

    #Main-Chat{
      height: 0vh;
      
    }

    #Main-Chat::-webkit-scrollbar{
      width: 15px;
      border-radius: 50px;
    }

    #Main-Chat::-webkit-scrollbar-thumb{
      width: 24px;
      background: rgb(30,31,34);
      border-left: 3px solid rgb(49,51,56);
      border-right: 3px solid rgb(49,51,56);
      border-radius: 25px;
    }

    #Main-Chat::-webkit-scrollbar-track{
      border-radius: 25px;
      border-style: solid;
      border-color: rgb(49,51,56);
      background-color: rgb(43,45,49);
    }

        #user-icon{
          border-radius: 50%;
        }

        #message-media{
          width: 350px;
          border-radius: 2.5%;
        }

    #text-box{
      width: 100%;
      overflow-y: scroll;
      padding-right: 175px;
      padding-left: 50px;  
      border-radius: 10px;   
    }



    [contenteditable] {
      outline: 0px solid transparent;
    }

    #text-box::-webkit-scrollbar{
      width: 4px;
      border-radius: 50px;
    }

    #text-box::-webkit-scrollbar-thumb{
      width: 10px;
      background: rgb(30,31,34);
      border-left: 0px solid rgb(49,51,56);
      border-right: 0px solid rgb(49,51,56);
      border-radius: 25px;
    }

    /* #text-box::-webkit-scrollbar-track{
      border-radius: 20px;
      border-style: solid;
      border-color: rgb(49,51,56);
      background-color: rgb(43,45,49);
    } */

    #Main-Input{
      min-height: 75px;
      max-height: 55vh;
      
    }

        .Input-Icon{
          padding-right: 1rem;
        }

:root{
  min-height: 500px;
  overflow: hidden;
  height: 100vh;
}

body{
  height: 100vh;
}


#People-Bar{
  width: 240px;
  overflow: scroll;
  height: 0vh;
}